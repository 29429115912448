import React from 'react';
import {FaWarehouse,FaUserEdit,FaUsers,FaChartLine,FaStoreAlt,FaBuffer,FaCalculator,FaBarcode,FaList} from 'react-icons/fa';
import {MdSettingsApplications,MdShoppingCart,MdGridOn} from 'react-icons/md';
import {Link} from 'react-router-dom';

import './Home.css'


function Home() {

let temp=window.location.href.split("/")
const p27=`${temp[0]}//${temp[2]}/p27/product`
const p25=`${temp[0]}//${temp[2]}/p25/job`
const fp=`${temp[0]}//${temp[2]}/fp/job`
const geo=`${temp[0]}//${temp[2]}/geo`
const p31=`${temp[0]}//${temp[2]}/p31/sticker`
const p29=`${temp[0]}//${temp[2]}/p29/transaction`
const p33=`${temp[0]}//${temp[2]}/p33/survey`

const refUser=React.createRef()
const refOldShop=React.createRef()
const refNewShop=React.createRef()

const {REACT_APP_NEWPAGE_URL,REACT_APP_OLDPAGE_URL}=process.env

return (
    <div className="home-hero">
        <div className="home-bg-screen">
            <div className="home-box">
                
                <div className="home-link"
                     onClick={e=>{
                         //console.log(REACT_APP_NEWPAGE_URL)
                         window.location.href = p25
                        }}>
                    <div>
                        <FaStoreAlt className="home-icon"/>
                    </div>
                    <div>
                        <h3>ร้านนอก</h3>
                    </div>
                </div>

    
                <div className="home-link"
                     onClick={e=>{
                        //console.log(REACT_APP_OLDPAGE_URL)
                        window.location.href = fp
                     }}>
                    <div>
                        <FaWarehouse className="home-icon"/>
                    </div>
                    <div>
                        <h4>ร้านใน งาน</h4>
                    </div>
                </div>

                <div className="home-link"
                     onClick={e=>{
                        //console.log(REACT_APP_OLDPAGE_URL)
                        window.location.href = p27
                     }}>
                    <div>
                        <FaBuffer className="home-icon"/>
                    </div>
                    <div>
                        <h5>ร้านในรายการ</h5>
                    </div>
                </div>

                <div className="home-link"
                     onClick={e=>{
                        //console.log(REACT_APP_OLDPAGE_URL)
                        window.location.href = geo
                     }}>
                    <div>
                        <FaCalculator className="home-icon"/>
                    </div>
                    <div>
                        <h5>CNC</h5>
                    </div>
                </div>

                <div className="home-link"
                     onClick={e=>{
                        //console.log(REACT_APP_OLDPAGE_URL)
                        window.location.href = p31
                     }}>
                    <div>
                        <FaBarcode className="home-icon"/>
                    </div>
                    <div>
                        <h5>สติกเกอร์</h5>
                    </div>
                </div>

                <div className="home-link"
                     onClick={e=>{
                        //console.log(REACT_APP_OLDPAGE_URL)
                        window.location.href = p29
                     }}>
                    <div>
                        <MdShoppingCart className="home-icon"/>
                    </div>
                    <div>
                        <h5>POS</h5>
                    </div>
                </div>
     

                <div className="home-link"
                     onClick={e=>{
                        //console.log(REACT_APP_OLDPAGE_URL)
                        window.location.href = p33
                     }}>
                    <div>
                        <FaList className="home-icon"/>
                    </div>
                    <div>
                        <h5>สำรวจตลาด</h5>
                    </div>
                </div>

            </div>
        </div>

    </div>
)
}

export default Home;
/*
<div className="home-link"
onClick={e=>{refProduct.current.click()}}>
<div>
   <FaWarehouse className="home-icon"/>
</div>
<div>
   <h3>คลังสินค้า</h3>
</div>
</div>
*/