
import React from 'react';

import Home from './page/home/Home'

//import Product from './page/product/Product'

import './App2.css'

function App() {


return(
<div className="bgc-lightGray" style={{height:"100vh",width:"100vw"}}>
   <div className="h-100">
        <Home/>
   </div>

</div>
)

}
export default App;

/*






*/